<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-user-lock"></i> กำหนดสิทธิ์การเข้าร้าน </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <div class="card custom-card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-2 mb-2">ชื่อร้านค้า :</div>
                <div class="col-sm-10">{{item.name}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="card custom-card">
            <div class="card-body">
              <div class="table-responsive padding_top_20">
                <table class="table align-middle table-bordered">
                  <thead>
                    <tr class="text-center" >
                      <th>ผู้ใช้</th>
                      <th>เลือก</th>
                      <!-- <th>View</th>
                      <th>Add</th>
                      <th>Edit</th>
                      <th>Remove</th> -->
                    </tr>
                  </thead>
                 <tbody>
                    <tr v-for="v in permission" :key="`list_store_permission_${v.id}`">
                      <td>{{v.name}} : {{v.fullname}}</td>
                      <td class="text-center">
                        <b-form-checkbox v-model="v.selected"/>
                        <!-- <b-form-checkbox @change="selectAll(v)" v-model="v.selectAll" :check="checkSelectAll(v)"/> -->
                      </td>
                      <!-- <td class="text-center">
                        <b-form-checkbox v-model="v.action.flag_view"/>
                      </td>
                      <td class="text-center">
                        <b-form-checkbox v-model="v.action.flag_insert"/>
                      </td>
                      <td class="text-center">
                        <b-form-checkbox v-model="v.action.flag_update"/>
                      </td>
                      <td class="text-center">
                        <b-form-checkbox v-model="v.action.flag_delete"/>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="float-right pt-5 d-flex">
            <span><router-link :to="'/store'" class="nav-link text-underline"> กลับ</router-link></span>
            <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
            <b-button variant="primary" @click="editHandler()" class="style_btn"><i class="fa fa-check"></i> บันทึก</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'store-view-page-customer-role',
    data () {
      return {
        item: {},
        permission: [],
        id: ''
      }
    },
    methods: {
      async editHandler () {
        const result = await this.HttpServices.putData(`/ab_store/permission/${this.item.id}`, {permission: JSON.stringify(this.permission)});
        if(result&&result.status.code=="200"){
          this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
            this.$router.push('/store');
          });
        }else{
          this.AlertUtils.alert('warning', result.status.message);
        }
      },
      onBackHandler(){
        this.$router.push("/store");
      },
      selectAll(item){
        item.action.flag_view = item.selectAll;
        item.action.flag_insert = item.selectAll;
        item.action.flag_update = item.selectAll;
        item.action.flag_delete = item.selectAll;
      },
      checkSelectAll(item){
        if(item.action.flag_view&&item.action.flag_insert&&item.action.flag_update&&item.action.flag_delete){
          item.selectAll = true;
        }else{
          item.selectAll = false;
        }
      },

      async getItem(){
        const result = await this.HttpServices.getData(`/ab_store/permission/${this.id}`);
        if(result&&result.status.code=="200"){
          this.item = result.data.store;
          this.permission = result.data.permission;
        }else{
          this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูลผู้ผลิต`, ()=>{
            this.$router.push('/store');
          });
        }
      }
    },
    async mounted() {
      this.id = await this.SessionStorageUtils.getSession("page-store");
      await this.getItem();
    }
  };
</script>
<style scope>
  .checkbox_style > .custom-control {
    left: 12px;
  }
</style>
